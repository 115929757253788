const MenuData=[
    {
        title:'Home',
        link:'/'
    },
    {
        title:'Blog',
        link:'/blog'
    },
    {
        title:'Projects',
        link:'/projects'
    }
]
export default MenuData;