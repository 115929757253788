const { createGlobalStyle } = require("styled-components");

const GlabalStyle=createGlobalStyle`
   *{
      font-family:'Roboto',sans-serif;
      margin:0;
      padding:0;
      box-sizing:border-box;  
    }
    ins.adsbygoogle {
    background: transparent;
   }
`
export default GlabalStyle;