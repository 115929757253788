import React  from "react"
import styled from "styled-components"
import { FaAdjust} from "react-icons/fa";
import { Link as GatsbyLink } from "gatsby";
const SideBar = ({ Open,MenuData,colorMode,setColorMode,sendEmail}) => {



  return ( 
        <Nav Open={Open}>
            {MenuData.map(({link,title},index)=>{
                return <NavLink 
                        key={index} 
                        to={link}>
                        {title}
                        </NavLink> 
            })}
             <NavBtn>
                {/* <Button 
                  onClick={sendEmail}
                  style={{padding:5}}
                  primary={colorMode === 'dark' ? 'true' : null}
                  round='true'>
                   Contact Me 
                  </Button>  */}
                  <FaAdjust 
                  style={{marginLeft:10}}
                  color={colorMode === 'dark' ? '#E91E63' : '#37e8ff'} 
                  size={'1.5em'} 
                  onClick={e => {
                    setColorMode(colorMode === 'dark' ? 'light' : 'dark')
                 }}
                  >
                  </FaAdjust>
            </NavBtn>
        </Nav> 
       )
}

export default SideBar;

const Nav=styled.div`
   background-color: #1d4354e0;
    position: absolute;
    top: 60px;
    right: 0px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
   display:${props=>props.Open===true?'flex':'none'};
   @media screen and (min-width:768px){
         display:none; 
     }  
`
const NavLink=styled(GatsbyLink)`
    display:flex;
    color:${props=>props.active?'#37e8ff':'white'};
    align-items:center;
    font-weight:bold;
    text-decoration: none;
    padding:1.2rem 1.5rem;
    box-shadow:none;
    height:100%;
    cursor:pointer;
    &:hover{
      color:#37e8ff;
      border-radius:20px;
    }
`
const NavBtn=styled.div`
     display:flex;
     align-items:center;
     justify-content:center;
     margin:1rem;
`