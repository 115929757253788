import React,{useState}  from "react"
import styled from "styled-components"
import { FaAdjust,FaWindowClose,FaBars} from "react-icons/fa";
import MenuData from "../data/Menudata";
import { useColorMode} from 'theme-ui';
import { Link as GatsbyLink } from "gatsby";
import SideBar from "./SideBar";

const Header = () => {
  const [colorMode, setColorMode] = useColorMode();
  const [MenuState, setMenuState] = useState(false);

  const sendEmail=()=> 
    {
      let email = "mailto:manoj8679778910@gmail.com";
      window.open(email);
     }

  return ( 
        <Nav>
            <NavLink 
                style={{fontSize:'20px',fontWeight:"bold"}}
                to="/">
                Manoj Bhardwaj
            </NavLink>
                {!MenuState&&
                   <Bars onClick={()=>setMenuState(true)}/>  
                }
                {MenuState&&
                   <CloseBtn onClick={()=>setMenuState(false)}/>  
                }
               <NavMenu> 
                {MenuData.map(({link,title},index)=>{
                return <NavLink 
                        key={index} 
                        to={link}>
                        {title}
                        </NavLink> 
                })}
                </NavMenu>
              <NavBtn>
    
                 <FaAdjust 
                  style={{marginLeft:15}}
                  color={colorMode === 'dark' ? '#E91E63' : '#37e8ff'} 
                  size={'2em'} 
                  onClick={e => {
                    setColorMode(colorMode === 'dark' ? 'light' : 'dark')
                  }}
                  >
                  </FaAdjust>
                  
            </NavBtn>
            <SideBar sendEmail={sendEmail} setColorMode={setColorMode} colorMode={colorMode} MenuData={MenuData} Open={MenuState}/>
        </Nav> 
       ) 
}

export default Header;

const Nav=styled.nav`
   height:80px;
   display:flex;
   background-color:#1d4354;
   justify-content:space-between;
   padding:0.5rem calc((100vw - 1300px)/2);
   z-index:100;
   align-items:center;
   position:relative;
   @media screen and (max-width:768px){
    height:60px;
   }
`
const NavLink=styled(GatsbyLink)`
    display:flex;
    color:${props=>props.active?'#37e8ff':'white'};
    align-items:center;
    font-weight:bold;
    text-decoration: none;
    padding:0 2rem;
    box-shadow:none;
    height:100%;
    cursor:pointer;
    &:hover{
      color:#37e8ff;
      border-radius:20px;
    }
`
const Bars=styled(FaBars)`
    display:none;
    @media screen and (max-width:768px){
     display:block;
     position:absolute;
     top:0;
     right:0;
     color:white;
     transform:translate(-100%,75%);
     font-size:1.8rem;
     cursor:pointer;
   }
`
const CloseBtn=styled(FaWindowClose)`
     display:block;
     position:absolute;
     top:0;
     right:0;
     color:white;
     transform:translate(-100%,75%);
     font-size:1.8rem;
     cursor:pointer;
     @media screen and (min-width:768px){
         display:none; 
     }
   
`
const NavMenu=styled.div`
     display:flex;
     align-items:center;
     margin-right:-48px;
     @media screen and (max-width:768px){
         display:none; 
     }
`
const NavBtn=styled.div`
     display:flex;
     align-items:center;
     margin-right:24px;
     @media screen and (max-width:768px){
         display:none; 
     }
`