import React from "react"
import Header from '../components/header';
import styled from 'styled-components'
import GlabalStyle from "./styles/Globalstyle";

const Layout = ({ children, title}) => {
    console.info(title)
  return (
     <> 
      <GlabalStyle/>
        <Header
         siteTitle={title}/>
        <StatsContiner>
        <main>{children}</main> 
        </StatsContiner>
     </>  
  )
}

export default Layout;

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
const StatsContiner=styled.div`
   width:100%;
   display:flex;
   flex-direction:column;
   justify-content:center;
   padding:4rem calc((100vw  - 1300px)/2);
   @media screen and (max-width:768px){
    padding:1rem;
    }
`